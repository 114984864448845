.tooltip {
  @apply relative;
}

.tooltip::after {
  @apply opacity-0 pointer-events-none py-1 px-2 font-sans font-normal text-sm text-white rounded-md absolute whitespace-nowrap z-10;

  background-color: #1f2937;
  transition: all 0.18s ease-out 0.18s;
  text-indent: 0;
  content: attr(aria-label);
  bottom: 100%;
  transform-origin: top;
  margin-bottom: 10px;
  left: 50%;
  transform: translate(-50%, 4px);
}

.tooltip::before {
  @apply w-0 h-0 opacity-0 pointer-events-none absolute z-10;

  border: 5px solid transparent;
  border-top-color: #1f2937;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  bottom: 100%;
  transform-origin: top;
  left: 50%;
  transform: translate(-50%, 4px);
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
  pointer-events: none;
}
