@tailwind base;
@tailwind components;
@tailwind utilities;

.w-10i {
  @apply w-10 !important;
}

[data-reach-dialog-overlay] {
  @apply bg-gray-900 bg-opacity-75;
}

[data-reach-dialog-content] {
  @apply p-6 w-auto;
}

.mobile-sidebar-container > div:first-child {
  @apply flex flex-col flex-1 h-full pt-5 pb-28;
}

.tab-item:first-child {
  @apply ml-6;
}

.toast-max-height {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

.first\:mt-0:first-child {
  margin-top: 0 !important;
}

table td div:empty::before,
table td:empty::before,
.empty-content:empty::before {
  @apply text-gray-400;
  content: "--";
}

.form-error::before,
.form-error::after {
  border: solid transparent;
  bottom: 100%;
  content: "";
  height: 0;
  left: 10px;
  pointer-events: none;
  width: 0;
  position: absolute;
}

.form-error::before {
  --tw-bg-opacity: 1;
  border-bottom-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  border-width: 6px;
  margin-left: -1px;
}

tr.loading td {
  @apply opacity-25 pointer-events-none;
}

.sticky-gradient {
  z-index: 1;
  /* backdrop-filter: blur(1px); */
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), white, white);
}

.underline-dotted {
  text-decoration: underline dotted;
}

.select-container {
  @apply rounded-md shadow-sm;
}

.select-container .select__control,
.select-container .select__control:hover {
  @apply sm:text-sm border-gray-300 shadow-sm rounded-md;
}

.select-container .select__control {
  transition: none;
  min-height: 42px;
}

@media (min-width: 640px) {
  .select-container .select__control {
    min-height: 38px;
  }
}

.select-container .select__control--is-focused,
.select-container .select__control--menu-is-open,
.select-container .select__control--is-focused:hover {
  @apply ring-indigo-500 border-indigo-500 outline-none ring-1;
}

.select-container .select__value-container {
  @apply px-3;
}

.select-container .select__placeholder {
  @apply sm:text-sm text-gray-500;
}

.select-container .select__option {
  @apply text-gray-900 sm:text-sm bg-white;
}

.select-container .select__input input {
  @apply shadow-none;
  box-shadow: none !important;
}

.pre {
  display: block;
  padding: 10px 30px;
  margin: 0;
  max-width: 80%;
  overflow: scroll;
}

.select-container .select__option--is-focused,
.select-container .select__option--is-focused:active {
  @apply bg-indigo-700 text-white;
}

[data-reach-menu-popover] {
  @apply origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none;
}

div:focus[data-reach-menu-list] {
  outline: none;
}

[data-reach-menu-item][data-selected] {
  @apply bg-gray-100;
}

[data-reach-menu-list] {
  @apply py-1;
}

[data-reach-menu-item],
[data-reach-menu-link] {
  @apply block px-4 py-2 text-sm text-gray-700 cursor-pointer;
}

[data-reach-menu-item][data-disabled],
[data-reach-menu-link][data-disabled] {
  @apply opacity-25 cursor-default;
}
